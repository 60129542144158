





























































































































































































































































































































































































.product-sheet-cell__ref {
  line-height: 1.2;
}

.product-sheet-cell {
  .el-icon-info {
    font-size: 1.25rem;
  }
}

td.cmr {
  /*height: 1px;*/
  padding: 0 !important;

  .cell {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.red {
        background: #D84A38 !important;
      }

      &.yellow {
        background: #D6A637 !important;
      }

      &.green {
        background: #35aa47 !important;
      }
    }
  }
}

.comment-label {
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  cursor: pointer;

  &.expanded {
    transform: rotate(90deg);
  }
}
