























































































































































































.filter-checkbox {
  display: flex;
  align-items: center;
  height: 40px;
}
